export default {
  general: {
    mainTitle: 'All set!'
  },
  emptyView2: {
    title: 'Second empty route'
  },
  becomeCustomerView: {
    notificationStart: 'With this form, we’re only requesting the information required to process the matter. More information on the ',
    notificationLink: 'processing of personal data',
    notificationEnd: ' at Posti.',
    companyInformationHeader: 'Company information',
    registrationInfoTooltip: 'With this form, we’re only requesting the information required to process the matter. More information on the processing of personal data at Posti.',
    companyName: 'Company name',
    businessId: 'Business ID',
    websiteUrl: 'Online store URL',
    country: 'Country',
    officialCompanyAddress: 'Street address',
    addressTooltip: 'If your company’s address has more than one line, separate the lines with a comma.',
    postalCode: 'Postal code',
    city: 'City/municipality',
    addressHeadline: 'Company address',
    addressSubtitle: 'This address is used as the company’s visiting, delivery and invoicing address. You can change the details later.',
    tradeRegister: 'The company is registered to',
    tradeRegisterLink: 'the Finnish trade register',
    mandatoryInfoText: '*Required information',
    mainUserInformationHeader: 'Administrator information',
    mainUserInformation: 'If you provide an email address that is already connected to an account in Posti’s Web Service, this new business account will be added to that account.',
    firstName: 'First name',
    lastName: 'Last name',
    emailAddress: 'Email address',
    phone: 'Phone number',
    creditCheckInfo: 'I here by confirm that:',
    creditCheckFirst: 'I have the right to act as the administrator of the company I registered in the manner required by the ',
    creditCheckLink: 'Terms of Use of Electronic Channels',
    creditCheckEnd: ' for Posti Ltd’s Contract Customers.',
    creditCheckSecond: 'the credit information of the company I registered can be checked.',
    buttonNext: 'Continue',
    buttonCancel: 'Cancel'
  },
  countries: {
    FI: 'Finland',
    SE: 'Sweden',
    PL: 'Poland',
    EE: 'Estonia',
    GB: 'Great Britain',
    CZ: 'Czech Republic',
    HU: 'Hungary',
    DK: 'Denmark',
    DE: 'Germany',
    ES: 'Spain',
    BE: 'Belgium',
    NO: 'Norway',
    LT: 'Lithuania',
    CH: 'Switzerland',
    IE: 'Ireland',
    NL: 'Netherlands',
    SK: 'Slovakia',
    FR: 'France',
    RO: 'Romania',
    LV: 'Latvia',
    BG: 'Bulgaria',
    PT: 'Portugal',
    GI: 'Gibraltar',
    RU: 'Russia',
    AT: 'Austria',
    IT: 'Italy',
    RS: 'Serbia'
  },
  verifyInformationView: {
    notificationText: 'You are about to register as Posti’s business customer. Check that the information below is correct.',
    buttonBack: 'Back',
    accountAlreadyExists: 'There is already a Posti customer account with this email address. Please activate the business customer access rights by clicking the link in the confirmation message and logging in to the service with your existing user account.'
  },
  emailVerificationView: {
    logOutButton: 'Log out',
    header: 'Almost finished! Confirm your email address',
    subtitle: 'We have sent you a confirmation message to:',
    bodyText: 'Finish creating your business account by clicking the link in the confirmation message. The link is valid for seven days. It may take a few minutes for the message to arrive.',
    requestText: 'Check your spam folder. If you cannot find the message, ',
    sendLink: 'request a new confirmation message.',
    customerServiceText: 'Did you not receive the link? Did you accidentally enter an incorrect email address?',
    customerServiceLink: 'Please contact the customer service.',
    cashCustomerNotification1: 'It looks like you already are a Posti business customer! We’ll now update your old customer information, terms and prices with new ones.',
    cashCustomerNotification2: 'You’ll receive a confirmation link in your email shortly. After clicking on the link, you can log in to OmaPosti Pro and start sending!',
    invoicingCustomerNotification1: 'We found that your company is already a Posti customer! We have now created a new logistics contract for you and merged it to your existing account. You may view and edit your company’s invoicing information in OmaPosti Pro.',
    invoicingCustomerNotification2: 'Confirm your email address, and you can log in to OmaPosti Pro and start shipping right away!',
    invoicingCustomerNotification3: 'We may get in touch with you, about consolidating your current agreement and the new contract.'
  },
  errorViewContractExists: {
    header: 'Contract already exist',
    subheader: '<0>Company {{companyName}}, {{businessId}} already has logistics contract.</0>',
    howToProceed: 'How do I proceed?',
    newAccountHeader: 'I want to create a new user account',
    newAccountBody: '<0>In order to create a new user account you need to be in contact with the person at your company that has user management rights, so that they can invite you from</0> <1>Users management in OmaPosti Pro</1>',
    accessRightsHeader: 'I need access rights in order to send/create shipments',
    accessRightsBody: '<0>Please login to</0> <1>Oma Posti Pro</1> <0>and head over to the sending section. If you don’t have access to sending, there will be instruction on how to gain it.</0>',
    didntKnowHeader: 'I didn’t know we already had a logistics contract',
    didntKnowBody: '<0>No worries, no harm done. The existing contract is unaffected and still in use. You can proceed as normal.</0><br/><br/><0>If you can’t see parcel products in the sending flow you most likely are missing the access rights.</0><br/><0>In order to get access you need to be in contact with the person at your company that handles User management inside of OmaPosti Pro.</0>',
    didntKnowLink: '<0>Read more about user management in our customer service portal</0>',
    negotiateHeader: 'I want to re-negotiate the current contract',
    negotiateLink: '<0>Please fill out this form to be in contact with sales</0>',
    talkHeader: 'I want to talk with someone',
    talkLink: '<0>Please fill out this form to be in contact with sales</0>'
  },
  contractView: {
    header: 'Read the contract carefully before signing it.',
    bottomPart1: 'Posti’s electronic ordering channel enables you to use Posti’s contract services and manage your own information (“Service”). In order to use the Service, your company must be Posti’s contract customer and it must have user codes. The Service is subject to the terms of use of the Electronic Channels of Posti Oy’s Contract Customers. You accept the terms of use and the Data Processing Agreements',
    bottomPart1_5: 'attached to the terms of use when you use the Services for the first time.',
    // eslint-disable-next-line
    bottomPart2: 'The valid product terms, Posti’s general terms of contract and price lists shall apply to the contract services available through the Service. You accept the terms applicable to Posti’s Service when you order the Service. Please carefully read the ',
    bottomPart3: 'contract and product terms',
    bottomPart4: 'and ',
    bottomPart5: 'the price lists',
    bottomPart6: ' before ordering any services. Not all Posti services are available without a separate customer ID.',
    buttonBack: 'Back',
    buttonAccept: 'Sign the contract',
    buttonDownload: 'Download as PDF',
    creditLimit: 'The credit limit for invoicing is 2000 €.',
    contractHeader: 'Terms of Use of Electronic Channels for Posti Ltd’s Contract Customers Jan 1, 2023',
    subtitle1: '1. Scope of Application',
    subtitle2: '2. Terms and Conditions of Agreement',
    subtitle3: '3. Activation of the Service',
    subtitle4: '4. Service',
    subtitle5: '5. Methods of Payment',
    subtitle6: '6. Identifiers and Administrator',
    subtitle7: '7. Customer’s Responsibilities',
    subtitle8: '8. The Customer’s integration interfaces to the Service',
    subtitle9: '9. Posti’s Responsibilities',
    subtitle10: '10. Changing and terminating the Service and the Terms of Use',
    subtitle11: '11. Validity of the Terms of Use',
    subtitle12: '12. Other Terms and Conditions',
    content1: '1.1. Posti Ltd (business ID: 2344200-4), Posti Distribution Ltd. (business ID: 0109357-9) and companies belonging to the same Group (“Posti”) offer their contract customers (“the Customer”) digital channels and services for using Posti’s contract services and managing the Customer’s own information (“the Service”). These terms of use (“Terms of Use”) apply to the Service used by the Customer.',
    content2: '2.1. The use of the Service shall be primarily subject to these Terms of Use and secondarily to Posti’s general terms and conditions of agreement. In addition, Posti may provide guidelines related to the use of the Service separately in the Service.',
    content22: '2.2. Should guidelines provided by Posti in the Service conflict with the Terms of Use, the guidelines shall prevail.',
    content23: '2.3.  Posti’s services received through the service are subject to the service’s product terms and Posti’s General Contract Terms for business customers as well as other possible documents as stated in section 20 of the General Contract Terms.',
    content3: '3.1. The activation of the Service requires that the Customer has agreed with Posti on a contract customer relationship that entitles the Customer to use Posti’s contract services.',
    content32: '3.2. The Customer approves the Service’s Terms of Use when starting to use the Service.',
    content4: '4.1. In the Service, the Customer can receive reports relating to the Posti services used, and order or use the services available in the Service.',
    content42: '4.2. The use of the Service shall fall under the Customer’s responsibility. Posti does not guarantee uninterrupted and error-free Service availability.',
    content43: '4.3. Some services may require the identification of the Customer or a party acting on the Customer’s behalf.',
    content44: '4.4. The Customer may only use the Service in their own internal operations. The Customer shall not have the right to resell or distribute the Service in any other similar manner.',
    content45: '4.5. Use of the Service may enable access to the use or ordering of products and services offered by third parties (“Other Service Provider”). Posti shall not be responsible for the products, services or operations of Other Service Providers, or for information provided by them. Agreements for products and services of Other Service Providers shall be concluded between the Customer and the Other Service Provider. Posti shall not be party to any such agreement and shall not be liable for any obligations related thereto.',
    content5: '5.1. Unless an agreement has been made with the customer (self-service customer) on invoicing, services must be paid for when ordered using the methods of payment available in the Service at the time. Posti shall have the right to charge the customer’s payments or set an authorization hold for the method of payment used by the customer.',
    content52: '5.2. The Service Account is a method of payment offered by Posti Ltd to its self-service customers. The Service Account can be used to pay for services offered by Posti in the Service.',
    content53: '5.3. The self-service customer shall be responsible for ensuring that the Service Account or another method of payment used by the Customer and approved by Posti always has sufficient funds to pay for the Customer’s purchases. The Service Account balance may not be overdrawn.',
    content54: '5.4. When the Customer transfers funds from the Customer’s bank account to the Service Account, the bank transfer must feature the reference number provided by Posti in order for the funds to be allocated to the Customer’s Service Account. Funds transferred by bank transfer ordered from the Customer’s own bank will usually be available in the Service Account on the banking day following the execution of the bank transfer. The time taken by bank transfers depends on agreements between banks. The Customer shall be aware of the fact that it may take several days for the bank transfer to be registered in the Service Account. When the Customer transfers funds from the Customer’s bank account to the Service Account with the Service Account’s online payment button, the funds will be available to the Customer in the Service Account immediately after the online payment succeeds.',
    content55: '5.5. Posti shall not pay interest for funds in the Service Account. In bookkeeping, the Customers’ funds are separated from Posti’s funds.',
    content56: '5.6. The Customer may, at any time, withdraw the funds available to the Customer in the Service Account. The funds will be paid to the bank account nominated by the Customer as the contra account of the Service Account. In addition to funds available to the Customer, the Service Account may include funds held for the Customer’s services with authorization holds or payment units granted by Posti as part of a campaign or as a refund that can only be used for paying for Posti’s services in the Network service and that cannot be withdrawn from the Service Account.',
    content6: '6.1. The Customer must specify at least one Administrator for Posti. Posti will approve the Customer’s registration and provide the Customer with the Administrator’s identifiers.',
    content62: '6.2. The Customer’s Administrator creates and maintains the identifiers of the Customer’s other users and acts as a liaison between Posti and the Customer in matters pertaining to the Service. The Customer shall be responsible for ensuring that the service portal always has up-to-date information about the Customer’s Administrator.',
    content63: '6.3. The Administrator has the right to use all components and materials to which the Customer has access in the Service. Non-Administrator identifiers may have limited access rights.',
    content64: '6.4. The Customer must store all identifiers with due care and in such a way that they do not fall into the hands of a third party for unauthorized use. Should the Customer’s identifier fall into the hands of a third party or become lost, the Customer themselves must disable the identifier’s access rights.',
    content65: '6.5. The identifiers are personal, and a person may use the Service with the same identifier when acting as the representative of different customers. The Customer shall be responsible for ensuring that the Customer’s representation right related to the identifiers is only accessible to persons who are entitled to act as the Customer’s representatives in the Service as enabled by the representation right. The Customer themselves must disable the representation rights related to the identifiers.',
    content66: '6.6. Use of the Customer’s identifiers is equivalent to the Customer’s signature.',
    content67: '6.7. Posti is entitled, but not obligated, to delete the Customer’s identifiers which have been inactive for over a year.',
    content7: '7.1. The Customer shall be responsible for the administration of the identifiers and the access rights related thereto. The Customer’s Administrator administers the Customer’s identifiers and the extent of the access rights related thereto.',
    content72: '7.2. The Customer’s Administrator shall be responsible for providing user support to the Customer’s other users.',
    content73: '7.3. The Customer shall be responsible for all actions performed in the Service with the Customer’s identifiers.',
    content74: '7.4. The Customer shall be responsible for ensuring that the computer and other hardware, software and data communications connections used by the Customer comply with the requirements specified by Posti and are sufficiently secured by, for instance, using up-to- date and efficient virus protection software and other equivalent means of security. The Customer shall be personally liable for the cost and operation of the equipment, software, and communications and data communications connections required for the use of the Service. When using the Service, the Customer shall be obliged to comply with the currently valid terms of use security guidelines, and other instructions of Posti.',
    content75: '7.5. The Customer must provide Posti with the information needed to carry out the Service. The Customer must notify Posti immediately of any changes to the information. The Customer shall be responsible for the accuracy of the information it provides in the Service. Posti shall be under no obligation to check or correct the information that the Customer provides in connection with the Service.',
    content76: '7.6. The Customer shall be responsible for information, files, documents or other material they store, process or transfer in the Service (“the Material”).',
    content77: '7.7. The Customer shall be responsible for ensuring that the Service is used according to law and good practice. Posti does not monitor Material produced, sent, transferred or otherwise processed with the aid of the Service, nor shall Posti be responsible for illegal or offensive content in such material. When using the Service, the Customer shall be responsible for refraining from producing, sending, storing or otherwise processing any material that violates copyright or other rights, good practice, law or official orders. Violations may result in an obligation to compensate for the damage or loss caused thereby. The Customer shall be responsible for the content and presentation format of the Material sent or otherwise processed by the Customer with the aid of or via the Service.',
    content78: '7.8. Before adding Material to the Service, the Customer must ensure that the Material does not breach the terms and conditions of agreement applicable to the Service or guidelines provided by Posti and that the Material does not contain viruses or other properties that may cause damage or loss. The Customer shall be responsible for ensuring that they do not import into the Service any Material that contains viruses or other harmful properties and that they do not distribute or otherwise harmfully process such Material.',
    content79: '7.9. When processing customer or other personal data in the Service, the Customer shall, as a controller, be responsible for adhering to the legislation that pertains to the processing of personal data. The Customer must make sure they are entitled to process the personal data in question and otherwise adhere to obligations decreed in law or required by authorities when processing personal data.',
    content8: '8.1. An agreement on an integration interface to the Service may be concluded with the Customer. In deviation of the above, the Customer may not personally administer integration identifiers.',
    content9: '9.1. Posti shall be responsible for providing the Service in accordance with good data processing practice.',
    content92: '9.2. Posti shall administer the Service in such a way that it is, by and large, available for use 24 hours a day, 7 days a week. Posti may temporarily suspend the Service due to, for example, alterations or repairs, or measures caused by system upkeep, maintenance or repair.',
    content93: '9.3. Posti shall be liable for paying damages for direct damage or loss within Posti’s scope of liability, caused to the Customer by Posti’s negligence and proved by the Customer, in accordance with Posti’s general terms and conditions of agreement.',
    content94: '9.4. Regardless of the above, Posti shall not be liable for any damage or loss caused by the destruction, loss or alteration of the Customer’s Material.',
    content10: '10.1. The Customer shall be aware of the fact that Posti is continuously developing the Service, and consequently, the Service and its content may change at any time. Changes to the Terms of Use shall be communicated in the Service.',
    content102: '10.2. Posti shall have the right to terminate the provision of the Service in part or in full. This change shall be separately communicated in the Service well in advance.',
    content103: '10.3. Posti shall have the right, for a justified reason, such as the Customer’s breach of the Terms of Use or another breach of agreement, to disable the Customer’s access rights without prior notification.',
    content104: '10.4. Posti shall have the right, without hearing the Customer, to suspend the Service and remove the Material that is suspected of violating another party’s rights or law, official instructions or good practice.',
    content11: '11.1. The Terms of Use enter into force on January 1, 2023, and will remain in force until further notice.',
    content112: '11.2. Posti may terminate the Terms of Use by communicating this 30 days in advance in the Service. The Customer’s access rights shall end upon the termination of the Customer’s contract customer relationship.',
    content12: '12.1. Should the Customer’s Material breach the Terms of Use or another agreement between the Customer and Posti, Posti shall be under no obligation to provide the Service or services in accordance with the agreements mentioned above. Should it become evident that the Material processed by the Customer with the aid of the Service contains offensive content, violates the copyright or other rights of another party, breaches the law or the Terms of Use or the terms and conditions of the Customer’s other agreements or may cause damage or loss, Posti shall have the right to remove the material in question without hearing the Customer.',
    content122: '12.2. All rights (right of ownership, copyright and other intellectual property rights) to the Service and to material related thereto, offered by Posti, shall remain with Posti or its licensors. Rights to the products and services of Other Service Providers and to material related thereto shall remain with the respective service providers or their licensors. The Customer shall not have any intellectual property rights to the Service or to software or material related thereto or to any similar items.',
    content123: '12.3. If the Service in accordance with this contract involves the processing of personal data owned by the Customer outside the EU, for example for IT system reasons, Posti may transfer personal data by legal means in order to perform the Service.',
    enterName: 'Sign the contract with the administrator’s full name:',
    errorDefault: 'Enter the administrator’s full name.',
    errorSecondary: 'Error in the name.',
    dialogHeader: 'Sign the contract',
    buttonSign: 'Sign',
    buttonCancel: 'Cancel',
    fullName: 'Full name',
    logoutDialogHeader: 'Are you sure you want to cancel the contract registration?',
    buttonLeaveAndLogout: 'Close and log out',
    buttonReturnToContract: 'Return to contract',
    logoutDialogText: 'If you do not complete the registration now, your information will be removed. You can register with the service later, but you will have to enter all your details again.'
  },
  errorView: {
    header: 'Failed to create an account',
    body: 'Unfortunately, the system failed to create a business customer account.',
    text: 'Complete ',
    link: 'this form',
    text2: ' to create an account for your company.',
    button: 'Return to Posti’s home page'
  },
  errorViewNotAcceptable: {
    header: 'Failed to create the account!',
    body: '<0>Unfortunately, the system failed to create a business customer account due to a credit check. You can use our sending services without a contract</0> <1>here</1>.'
  },
  errorViewConflict: {
    header: 'Failed to create the account!',
    body: '<0>Unfortunately, the system failed to create a business account because the company is already a customer of Posti. If you want to start using new services, you can submit a contact request using</0> <1>the form</1>.'
  },
  pricingView: {
    small: 'Small parcel',
    normal: 'Postal parcel',
    homeParcelCity: 'Home parcel',
    homeParcelCityDetails: '(City area)',
    homeParcelOther: 'Home parcel',
    homeParcelOtherDetails: '(Other area)',
    expressParcel: 'Express parcel',
    customerReturn: 'Customer return',
    dialog: {
      header: 'Regional pricing of home delivery services',
      contentHeader: 'City areas',
      contentBody: 'The postal codes for Finland’s largest city centers are covered (see the list below). Approximately 40% of the Finnish population live in these areas.',
      subHeader: 'Other areas',
      subBody: 'Other areas refer to areas other than the centers of Finland’s largest cities, e.g. suburbs and rural areas.',
      postcodeStart: 'Go to the  ',
      postcodeLink: 'Home Parcel',
      postcodeEnd: ' page to download the Home Parcel postal code list.',
      button: 'Close'
    },
    area: {
      helsinki: 'Helsinki metropolitan area',
      turku: 'Turku region',
      tampere: 'Tampere region',
      oulu: 'Oulu',
      jyväskylä: 'Jyväskylä',
      lahti: 'Lahti',
      kuopio: 'Kuopio'
    },
    header: 'Send parcels more affordably',
    subtitle: 'Prices',
    service: 'Parcels',
    pricesFrom: 'Price starting from',
    fuelPart1: 'A ',
    fuelLink: 'fuel surcharge',
    fuelPart2: ' will be added to parcel prices. Minimum invoicing weight 1 kg.',
    buttonDownload: 'Download the price list',
    payFor: 'How is the price calculated?',
    titleOne: 'Item',
    titleTwo: 'Fuel',
    titleThree: 'Additional services',
    titleFour: 'Invoicing',
    titleFive: 'Sending a <br/> kettlebell',
    titleSix: 'Sending a <br/> pillow',
    titleSeven: 'Value-added tax (VAT)',
    serviceOne: 'The price is determined on the basis of the parcel’s actual weight or volume weight (see the example below).',
    serviceTwo: 'Fuel surcharge will be added to prices.',
    serviceThree: 'The price changes if you order additional services (e.g. Cash on Delivery).',
    serviceFour: 'If you send more than 100 € of parcels per week, we will send an invoice on a weekly basis. Less than 100 € is invoiced every month. If the amount billed is less than 100 € per month, we will charge a 10 € billing surcharge. Paper invoice price 5.24 € + VAT/invoice.',
    serviceFive: 'If you are sending a kettlebell in a parcel sized 43 x 35 x 18 cm, the actual weight of the parcel (16 kg) is more than its volume weight (0.43 m x 0.35 m x 0.18 m x 250 kg/m3 = 6.77 kg). In this case, the price is calculated on the basis of the actual weight 16 kg (postal parcel), e.g. (5.00 € + (0.20 €/kg x 16 kg)) x 1.12 = 9.14 € (The price includes 12 % full surcharge but not other charges or VAT).',
    serviceSix: 'If you are sending a pillow in a parcel sized 43 x 35 x 18 cm, the parcel’s actual weight (less than 1 kg) is less than its volume weight (0.43 m x 0,35 m x 0.18 m x 250 kg/m3 = 6.77 kg). In this case, the price is calculated on the basis of the volume weight 6.77 kg (postal parcel), e.g. (5.00 € + (0.20€/kg x 6.77kg)) x 1.12 = 7.11 € (The price includes 12 % full surcharge but not other charges or VAT).',
    serviceSeven: 'VAT is not included in the example prices. VAT valid at the time will be added to prices during invoicing. No VAT is added to the prices for shipments addressed to the Åland Islands, special areas outside the European Union’s excise and VAT zone and non-EU member states.',
    weightVolume: 'Item price: actual weight or volume weight?',
    weightVolumeSubtitleStart: 'The price is determined by the parcel’s actual weight or volume weight (width x length x height x 250 kg/m3 **), whichever is greater. Minimum weight is 100 g. Example:',
    standardStart: '** 250 kg/m',
    standardSup: '3',
    standardEnd: 'is a standard measurement for calculating volume weight in logistics.',
    buttonCancel: 'Back',
    buttonNext: 'Continue',
    deliveryZone: '*A delivery zone surcharge will be added to Express parcels in accordance with the parcel’s destination postal code: 0.5 €/item for zone 1 and 1 €/item for zone 2. See the delivery zones',
    deliveryZoneLink: 'here',
    pricesValid: 'Prices valid until further notice',
    otherServicePrices: 'Domestic services listed are offered by Posti Ltd and International services listed are offered by Posti Ltd or Posti Distribution Ltd. Other services offered by Posti Ltd or Posti Distribution Ltd are charged according to the list prices.',
    invoicingDetails: 'Invoicing details for existing billing customers will not change',
    internationalParcels: 'International Parcels',
    seePricesListBelow: 'See price list below'
  },
  //Includes only the translations that differ from the default pricing view
  ecommercePricingView: {
    pricesValid: '<strong>Price level 1</strong> (valid until further notice) less than 500 parcels during the calendar year',
    pricesValidTwo: '<strong>Price level 2</strong> (valid until further notice) more than 500 parcels during the calendar year',
    cardKettlebell: 'If you are sending a kettlebell in a <strong>parcel sized 43 x 35 x 18 cm</strong>, the actual weight of the parcel (16 kg) is more than its volume weight (0.43 m x 0.35 m x 0.18 m x 250 kg/m3 = 6.77 kg). In this case, the price is calculated on the <strong>basis of the actual weight 16 kg</strong> (postal parcel), e.g. 5.90 € + (0.30 €/kg x 16 kg) = <strong>10.70 €</strong> (other charges or VAT are not included).',
    cardPillow: 'If you are sending a pillow in a <strong>parcel sized 43 x 35 x 18 cm</strong>, the parcel’s actual weight (less than 1 kg) is less than its volume weight (0.43 m x 0.35 m x 0.18 m x 250 kg/m3 = 6.77 kg). In this case, the price is calculated on the <strong>basis of the volume weight 6.77 kg</strong> (postal parcel), e.g. 5.90€ + (0.30€/kg x 6.77kg) = <strong>7.93 €</strong> (other charges or VAT are not included).',
    serviceHeader: 'Useful information related to pricing',
    titleNoFees: 'No additional fees',
    bodyNoFees: 'No invoice surcharge or fuel surcharge in Starterpack products.',
    serviceFour: 'If you send more than 100 € of parcels per week, we will send an invoice on a weekly basis. Less than 100 € is invoiced every month. Paper invoice price 5.24 € + VAT/invoice.',
    info: '* The price is determined by the parcel’s actual weight or volume weight (width x length x height x 250 kg/m3 *), whichever is greater. Minimum weight 100 g and maximum weight 25 kg.',
    infoLink: 'Read more about freight weight.',
    infoSecond: '** A delivery zone surcharge will be added to Express parcels in accordance with the parcel’s destination postal code: 0,6 €/item for zone 1 and 1,2 €/item for zone 2. See the delivery zones',
    infoSecondLink: 'here',
    readMore: 'Learn more about Tier 2 pricing in the price attachment',
    infoThird: 'Services listed above are offered by Posti Ltd. Other services offered by Posti Ltd or Posti Distribution Ltd are charged according to the',
    infoThirdLink: 'list prices',
    normalParcelSmall: 'Postal parcel (max. 40 x 32 x 26 cm)',
    normalParcelLarge: 'Postal parcel (over 40 x 32 x 26 cm)*',
    homeParcelCity: 'Home parcel (max. 40 x 32 x 26 cm)',
    homeParcelOther: 'Home parcel (over 40 x 32 x 26 cm)*',
    deliveryHome: 'Home',
    deliveryPickup: 'Pickup point',
    deliveryBusiness: 'Company',
    expressParcel: '**Express parcel (max. 40 x 32 x 26 cm)',
    expressParcelLarge: '**Express parcel (over 40 x 32 x 26 cm)*',
    returnSmall: 'Return (max. 40 x 32 x 26 cm)',
    returnLarge: 'Return (over 40 x 32 x 26 cm)*',
    modalHeader: 'How to calculate the <br/> freight weight?',
    modalInfo: 'Freight weight means that the price of the shipment is determined on the basis of the parcel’s actual weight or volume weight (width x length x height x 250 kg/m3 *), whichever is the greater. The minimum weight is 100 g and the maximum weight is 25 kg. The freight weight affects the pricing of Home Parcels and Postal Parcels and returns with dimensions exceeding 40 x 32 x 26 cm.',
    otherServicesHeader: 'Other parcel services',
    otherServicesText: 'In addition to the services on this page, we have also other services available. Please download the full list of services and prices here:',
    tableHeaders: {
      shipmentPrices: 'Shipment prices',
      item: '€ / item',
      kg: '€ / kg*',
      delivery: 'Delivery',
      customerReturn: 'Customer return or unclaimed shipment'
    },
    campaignTitle: 'Prices for small online stores after the summer campaign***',
    campaignInfo: '*** The campaign runs from May 16 to August 31, 2022. Small Parcel 4.00 € Postal Parcel 5.50 € and Return 6.50 €. (The fixed price will be used when the parcels size does not exceed 40 x 32 x 26 cm. Otherwise, the discounted price applies to the fixed part of the Postal Parcel and Return services.)',
    currentPrices: 'Current prices',
    upcomingPrices: 'We are updating the price list of the Starter Package 26.2.2023. Download the new price list below.'
  },
  becomeCustomerWizard: {
    0: 'Register as a business customer',
    1: 'Confirm registration information',
    2: 'Business customer price list',
    3: 'Read and sign the contract',
    4: 'Confirm the email address',
    5: 'Ready',
    6: 'Thank you'
  },
  footer: {
    help: 'How can we help you?',
    support: 'Contact customer service'
  },
  errors: {
    officialNameField: {
      valueMissing: 'Enter the company name',
      patternMismatch: 'Company name is invalid'
    },
    businessIdField: {
      valueMissing: 'Enter a valid business ID',
      patternMismatch: 'Error in the business ID. Enter a Finnish business ID in the format 1234567-8',
      badInput: 'Business ID is invalid'
    },
    addressField: {
      valueMissing: 'Enter the company’s address',
      patternMismatch: 'Address is invalid'
    },
    postalCodeField: {
      valueMissing: 'Enter the postal code',
      patternMismatch: 'Postal code is invalid'
    },
    cityField: {
      valueMissing: 'Enter the city/municipality',
      patternMismatch: 'City is invalid'
    },
    firstName: {
      valueMissing: 'Enter the first name',
      patternMismatch: 'First name is invalid'
    },
    lastName: {
      valueMissing: 'Enter the last name',
      patternMismatch: 'Last name is invalid'
    },
    email: {
      valueMissing: 'Enter the email address',
      patternMismatch: 'Error in the email address. Enter an email address in the format peppi@mail.com.'
    },
    phoneNumber: {
      valueMissing: 'Phone number is mandatory',
      patternMismatch: 'Phone number value is invalid'
    },
    websiteUrlField: {
      patternMismatch: 'Online store URL value is invalid'
    }
  },
  header: {
    language: 'English',
    logOut: 'Log out'
  },
  maintenanceView: {
    header: 'Registration is not possible at the moment',
    body: 'Our website is under maintenance and the registration of new business customers is not possible at the moment. Please try again later.',
    button: 'Return to Posti’s home page'
  },
  cash: {
    fourForty: '4.40 €',
    fourFifty: '4.50 €',
    fiveNinety: '5.90 €',
    fiveSeventy: '5.70 €',
    thirtyCents: '0.30 €',
    eightFifty: '8.50 €',
    eightNinety: '8.90 €',
    tenFifty: '10.50 €',
    tenNinety: '10.90 €',
    sixNinety: '6.90 €',
    sixSeventy: '6.70 €'
  }
};